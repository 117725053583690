import { VisualResponse, GetVisualAssetParams } from './types';
import { apiBaseUrlV1 } from '../../../env';
import { rootApi } from '..';

const BASE_URL = apiBaseUrlV1('structure/v1/visuals');

interface ValidateProps {
  form: FormData;
  id?: string | undefined;
}

export interface BulkImportItem {
  type: 'visual' | 'visual_category';
  id: null | string;
  code: number;
  detail: null | string;
}

export interface BulkImportResponse {
  [key: string]: BulkImportItem;
}

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getVisuals: builder.query<VisualResponse[], void>({
      query: () => `${BASE_URL}`,
      providesTags: ['Visuals'],
    }),
    getVisualsByCategory: builder.query<VisualResponse[], string>({
      query: (category) => `${BASE_URL}?category=${category}`,
      providesTags: ['VisualsByCategory'],
    }),
    getVisual: builder.query<VisualResponse, string>({
      query: (id) => `${BASE_URL}/${id}`,
      providesTags: (_, __, id) => [{ type: 'Visuals', id }],
    }),
    getVisualDocument: builder.query<string, string>({
      query: (id) => ({
        url: `${BASE_URL}/${id}/document`,
        responseHandler: (response) => response.text(),
      }),
      providesTags: (_, __, id) => [{ type: 'VisualDocuments', id }],
    }),
    getVisualAsset: builder.query<string, GetVisualAssetParams>({
      query: ({ id, version }) => ({
        url: `${BASE_URL}/${id}/asset/${version}`,
        responseHandler: (response) => response.text(),
      }),
      providesTags: (_, __, params) => [
        { type: 'VisualAssets', id: params.id },
      ],
    }),
    validateVisual: builder.mutation<void, ValidateProps>({
      query: ({ id, form }) => ({
        url: `${BASE_URL}${id ? `/${id}` : ''}?dryRun=true`,
        method: 'POST',
        body: form,
      }),
    }),
    createVisual: builder.mutation<VisualResponse, FormData>({
      query: (form) => ({
        url: BASE_URL,
        method: 'POST',
        body: form,
      }),
      invalidatesTags: [
        'Visuals',
        'VisualDocuments',
        'VisualAssets',
        'VisualsByCategory',
      ],
    }),
    updateVisual: builder.mutation<
      VisualResponse,
      { id: string; form: FormData }
    >({
      query: ({ id, form }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body: form,
      }),
      invalidatesTags: [
        'Visuals',
        'VisualDocuments',
        'VisualAssets',
        'VisualsByCategory',
      ],
    }),
    deleteVisual: builder.mutation<void, string>({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'Visuals',
        'VisualDocuments',
        'VisualAssets',
        'VisualsByCategory',
      ],
    }),
    bulkImport: builder.mutation<BulkImportResponse, FormData>({
      query: (form) => ({
        url: `${BASE_URL}/bulk`,
        method: 'POST',
        body: form,
        redirect: 'follow',
      }),
      invalidatesTags: ['Visuals', 'VisualsByCategory', 'VisualCategories'],
    }),
  }),
});

export const {
  useGetVisualsQuery,
  useGetVisualsByCategoryQuery,
  useGetVisualQuery,
  useGetVisualDocumentQuery,
  useGetVisualAssetQuery,
  useCreateVisualMutation,
  useValidateVisualMutation,
  useUpdateVisualMutation,
  useDeleteVisualMutation,
  useBulkImportMutation,
} = extendedApi;
