import React, { useEffect, useState } from 'react';
import { ListItemIcon, MenuItem } from '@mui/material';
import { CloudOutlined } from '@mui/icons-material';
import { useAppSelector } from '../../../redux/store/index';
import { IAppState } from '../../../typescript/interfaces/appstate.interface';
import { KeyboardAndMouseIcon } from '../../../icons';

const originLibrary = {
  '.rc.procaas.us': 'https://rc.procaaso.net',
  '.stage.dev.procaas.us': 'https://stage.dev.paracloud.ai',
};

const getMessage = (env: 'cloud' | 'edge') =>
  `Go to ${env === 'edge' ? 'cloud' : 'HMI'} mode`;
const getOrigin = () => {
  const { origin } = window.location;
  const cloudOrigin = Object.entries(originLibrary).find(
    ([key]) => origin.indexOf(key) !== -1,
  )?.[1];
  return cloudOrigin;
};

export const ConnectedHMIStorage = 'connectedHMI';

const EnvModeButton = () => {
  const environment = useAppSelector((state: IAppState) => state.environment);

  const [isButtonExists, setIsButtonExists] = useState<boolean>(false);

  useEffect(() => {
    const connectedHMI = localStorage.getItem(ConnectedHMIStorage);
    if (connectedHMI || environment === 'edge') setIsButtonExists(true);
  }, []);

  const forwardEnvironment = () => {
    if (environment === 'edge') {
      const newOrigin = getOrigin();
      const newLocation = `${newOrigin}${window.location.pathname}`;

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.append(ConnectedHMIStorage, window.location.origin);

      window.location.href = `${newLocation.replace(
        window.location.search,
        '',
      )}?${searchParams.toString()}`;

      return;
    }
    const connectedHMI = localStorage.getItem(ConnectedHMIStorage);
    if (!connectedHMI) return;
    window.location.href = connectedHMI;
  };

  if (!isButtonExists) return null;

  return (
    <MenuItem
      id="UserMenu-env-button"
      onClick={forwardEnvironment}
      sx={{ p: '6px' }}
    >
      <ListItemIcon>
        {environment === 'edge' ? <CloudOutlined /> : <KeyboardAndMouseIcon />}
      </ListItemIcon>
      {getMessage(environment)}
    </MenuItem>
  );
};

export default EnvModeButton;
