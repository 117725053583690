import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  TextField,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import Input from '../Input';
// eslint-disable-next-line import/no-cycle
import { ROUTES_PATH } from '../../constants';
import { ModalContent, StyledSelect, StyledAutocomplete } from './styles';
import { SEM_VER_VERSIONS } from '../../components/project/builder/mocks';
import DialogComponent from './DialogComponent';
import { setBreadcrumb } from '../../redux/modules/breadcrumbs/slice';
import { useGetSystemConfigurationTemplatesQuery } from '../../redux/services/systemConfigurationTemplate/api';
import { useCreateProjectMutation } from '../../redux/services/ensProjects/api';
import useSnackbar from '../../hooks/useSnackbar';
import Loader from '../Loader';
import { useAppSelector } from '../../redux/store';
import { IAppState } from '../../typescript/interfaces/appstate.interface';
import {
  SOFTWARE_PACKAGE_DESCRIPTION,
  SOFTWARE_PACKAGE_TYPES,
} from '../../redux/modules/projects/slice';
import { useCreateFnsProjectMutation } from '../../redux/services/fnsProjects/api';
import { tryGetErrorMessageOrNull } from '../../redux/utils';
import withBreadcrumbs, {
  HandleBreadcrumbsProps,
  TypeOfAction,
} from '../../hoc/withBreadcrumbs';

const filterOptions = (options, state) => {
  const newOptions = [];
  options.forEach((el) => {
    if (el.name.toLowerCase().includes(state.inputValue.toLowerCase()))
      newOptions.push(el);
  });
  return newOptions;
};

interface Props {
  isOpened: boolean;
  closeModal: () => void;
  templateId?: string;
  defaultView?: string;
  handleBreadcrumbs?: (value: HandleBreadcrumbsProps) => void;
}

const initialState = (defaultView: string) => {
  const createProjectData = JSON.parse(
    sessionStorage.getItem('createProjectData'),
  );
  return {
    softwareType: defaultView,
    name: createProjectData?.name || '',
    description: createProjectData?.description || '',
    basedOn: createProjectData?.basedOn || '',
    version: createProjectData?.version || SEM_VER_VERSIONS[0],
  };
};

const CreateProject = (props: Props): JSX.Element => {
  const { isOpened, closeModal, templateId, defaultView, handleBreadcrumbs } =
    props;
  const history = useHistory();
  const isDemo = useAppSelector((state: IAppState) => state.isDemo);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const [isNotExists, setIsNotExists] = useState<boolean>(false);

  const {
    data: systemConfigurationTemplates = [],
    isLoading: systemConfigurationLoading,
  } = useGetSystemConfigurationTemplatesQuery(undefined, {
    refetchOnMountOrArgChange: true,
    pollingInterval: isNotExists ? 100 : 0,
  });
  const [
    createProject,
    {
      isSuccess: wasEnsProjectCreationSuccessful,
      data: newCreatedProject,
      error: createProjectError,
    },
  ] = useCreateProjectMutation();
  const [
    createFnsProject,
    {
      isSuccess: wasFnsProjectCreationSuccessful,
      data: newFnsProject,
      error: createFnsProjectError,
    },
  ] = useCreateFnsProjectMutation();

  const { showForbiddenError, showSuccess } = useSnackbar();

  const dispatch = useDispatch();

  const [state, setState] = useState(initialState(defaultView));

  useEffect(() => {
    if (state.basedOn !== '') {
      if (!systemConfigurationTemplates?.find(({ id }) => id === state.basedOn))
        setIsNotExists(true);
      if (systemConfigurationTemplates?.find(({ id }) => id === state.basedOn))
        setIsNotExists(false);
    }
  }, [systemConfigurationTemplates?.length]);

  const isActionButtonDisabled = useMemo(() => {
    const stateName = state.name.trim();
    const description = state.description.trim();
    const versionValue = state?.version?.value;
    const { basedOn } = state;

    if (state.softwareType === 'FNS') {
      return (
        !stateName ||
        stateName.length > 40 ||
        !description ||
        description.length > 1024
      );
    }

    return (
      !stateName ||
      stateName.length > 40 ||
      !description ||
      description.length > 1024 ||
      !versionValue ||
      !basedOn
    );
  }, [state]);

  const sortedSystemConfiguration = useMemo(() => {
    if (systemConfigurationTemplates)
      if (systemConfigurationTemplates?.slice())
        return systemConfigurationTemplates
          ?.slice()
          ?.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          ?.map(({ name, id }) => ({ name, id }));
    return [];
  }, [systemConfigurationTemplates]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setState((prevState) => ({
      ...prevState,
      [name]:
        name === 'version'
          ? SEM_VER_VERSIONS.filter(({ id }) => id === value)[0]
          : value,
    }));
  };

  const handleSave = () => {
    if (state.softwareType === 'FNS') {
      createFnsProject({
        name: state.name.trim(),
        description: state.description.trim(),
      });
      return;
    }
    createProject({
      ...state,
      archived: false,
    });
  };

  const handleCloseModal = () => {
    sessionStorage.removeItem('createProjectData');
    setState(initialState(defaultView));
    closeModal();
  };

  useEffect(() => {
    if (!wasEnsProjectCreationSuccessful) return;
    showSuccess(
      `${newCreatedProject?.name} project has been successfully created`,
    );

    handleCloseModal();
    handleBreadcrumbs({
      value: [
        {
          name: 'Projects',
          url: `${ROUTES_PATH.PROJECTS}/ens/${newCreatedProject?.id}`,
        },
      ],
      typeOfAction: TypeOfAction.SET_EMPTY,
    });
    history.push(`${ROUTES_PATH.PROJECT_DETAIL}/${newCreatedProject?.id}`);
  }, [wasEnsProjectCreationSuccessful]);

  useEffect(() => {
    if (!wasFnsProjectCreationSuccessful) return;
    showSuccess(`${newFnsProject?.name} project has been successfully created`);
    handleCloseModal();
    if (isDesktop) {
      history.push(`${ROUTES_PATH.PROJECTS}/fns/${newFnsProject?.id}`);
      return;
    }
    handleBreadcrumbs({
      value: [
        {
          name: newFnsProject?.name,
          url: `${ROUTES_PATH.EXTENDED_PROJECT}/${newFnsProject?.id}`,
          id: newFnsProject?.id,
        },
      ],
      typeOfAction: TypeOfAction.ADD,
    });
    history.push(`${ROUTES_PATH.EXTENDED_PROJECT}/${newFnsProject?.id}`);
  }, [wasFnsProjectCreationSuccessful]);

  useEffect(() => {
    showForbiddenError({
      error: createProjectError,
      customForbiddenMessage:
        "You don't have enough permissions to create a project",
      customDefaultMessage: "Project hasn't been created. Something went wrong",
    });
  }, [createProjectError]);
  useEffect(() => {
    showForbiddenError({
      error: createFnsProjectError,
      customForbiddenMessage:
        "You don't have enough permissions to create a project",
      customDefaultMessage:
        tryGetErrorMessageOrNull(createFnsProjectError) ??
        "Project hasn't been created. Something went wrong",
    });
  }, [createFnsProjectError]);

  const handleEditConfiguration = () => {
    sessionStorage.setItem('createProjectData', JSON.stringify({ ...state }));
    dispatch(
      setBreadcrumb([
        {
          name: `New template`,
          url: `${ROUTES_PATH.CREATE_CONFIG}`,
        },
      ]),
    );
    history.push(`${ROUTES_PATH.CREATE_CONFIG}/`, {
      type: 'template',
    });
  };

  useEffect(() => {
    if (templateId) {
      setState((prevState) => ({
        ...prevState,
        basedOn: templateId,
      }));
    }
  }, [templateId]);

  return (
    <DialogComponent
      isOpened={isOpened}
      closeModal={handleCloseModal}
      title="Add a project"
      actionTitle="Add"
      handleAction={handleSave}
      isActionButtonDisabled={isActionButtonDisabled}
      actionId="AddProject-add_button"
      cancelId="AddProject-cancel_button"
    >
      {!systemConfigurationLoading ? (
        <ModalContent>
          {isDemo ? (
            <>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor="AddProject-type">
                  <span>Project type</span>
                </InputLabel>
              </FormControl>
              <StyledSelect
                id="AddProject-type"
                variant="outlined"
                value={state.softwareType}
                onChange={handleChange}
                name="softwareType"
                sx={{
                  mt: 1,
                  mb: '1.5rem',
                  '& .MuiListItemText-secondary': { display: 'none' },
                }}
                displayEmpty
                fullWidth
                MenuProps={{
                  MenuListProps: {
                    id: 'AddProject-type-list',
                    sx: {
                      maxWidth: '428px',
                      ' li.Mui-disabled': {
                        display: 'none !important',
                      },
                      '& .MuiListItemText-secondary': {
                        whiteSpace: 'normal',
                      },
                    },
                  },
                }}
              >
                {SOFTWARE_PACKAGE_TYPES.map((packageType) => (
                  <MenuItem
                    id={`AddProject-type-${packageType.toLowerCase()}`}
                    key={packageType.toLowerCase()}
                    value={packageType}
                  >
                    <ListItemText
                      primary={packageType}
                      secondary={SOFTWARE_PACKAGE_DESCRIPTION[packageType]}
                    />
                  </MenuItem>
                ))}
              </StyledSelect>
            </>
          ) : null}
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="name">
              <span>Project name</span>
              <span>{state.name.length}/40</span>
            </InputLabel>
            <Input
              value={state.name}
              onChange={handleChange}
              name="name"
              placeholder="Enter project name"
              id="name"
              inputProps={{
                maxLength: 40,
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="AddProject-description">
              <span>Description</span>
              <span>{state.description.length}/1024</span>
            </InputLabel>
            <Input
              value={state.description}
              onChange={handleChange}
              placeholder="Enter description"
              id="AddProject-description"
              name="description"
              multiline
              rows={4}
              maxRows={8}
              sx={{ p: 0 }}
              inputProps={{
                maxLength: 1024,
              }}
            />
          </FormControl>
          {state.softwareType === 'ENS' ? (
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="version">
                ProCaaSo framework version
              </InputLabel>
              <StyledSelect
                id="version"
                variant="outlined"
                value={state.version.id}
                onChange={handleChange}
                name="version"
                disabled={SEM_VER_VERSIONS.length === 0}
                MenuProps={{
                  MenuListProps: {
                    id: 'AddProject-version-list',
                  },
                }}
              >
                {SEM_VER_VERSIONS.map((c) => (
                  <MenuItem
                    id={`AddProject-version-${c.value}`}
                    key={c.id}
                    value={c.id}
                  >
                    {c.value}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          ) : null}
          {state.softwareType === 'ENS' ? (
            <Box display="flex" alignItems="flex-end">
              <FormControl sx={{ mb: 0 }} fullWidth>
                <InputLabel shrink htmlFor="basedOn">
                  System configuration
                </InputLabel>
                <StyledAutocomplete
                  id="basedOn"
                  options={sortedSystemConfiguration}
                  getOptionLabel={(option: any) => {
                    const op = sortedSystemConfiguration?.find(
                      (s) => s.id === option,
                    );
                    return op?.name || '';
                  }}
                  value={state?.basedOn}
                  onChange={(e: any, newValue: any) => {
                    setState((prevState) => ({
                      ...prevState,
                      basedOn: (newValue && newValue.id) || '',
                    }));
                  }}
                  componentsProps={{
                    paper: {
                      sx: {
                        '& .MuiAutocomplete-listbox': { maxHeight: '360px' },
                      },
                    },
                    popper: { placement: 'top' },
                    clearIndicator: { id: 'AddProject-clear-config' },
                    popupIndicator: { id: 'AddProject-open-config' },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select configuration"
                    />
                  )}
                  filterOptions={filterOptions}
                  renderOption={(oProps, c: any) => (
                    <Box
                      sx={{
                        display: c.name.length > 0 ? 'flex' : 'none',
                        '&:hover .MuiIconButton-root': { opacity: 1 },
                      }}
                    >
                      <MenuItem
                        {...oProps}
                        key={c.id}
                        value={c.id}
                        sx={{
                          flex: 1,
                          '&.Mui-focused': {
                            backgroundColor: 'transparent !important',
                          },
                        }}
                      >
                        {c.name}
                      </MenuItem>
                    </Box>
                  )}
                  disabled={
                    systemConfigurationTemplates?.length === 0 ||
                    templateId != null ||
                    (state.basedOn &&
                      !systemConfigurationTemplates?.find(
                        ({ id }) => id === state.basedOn,
                      ))
                  }
                />
              </FormControl>
              {!templateId && (
                <Button
                  startIcon={<AddRounded />}
                  component={Link}
                  onClick={() => handleEditConfiguration()}
                  variant="outlined"
                  sx={{
                    minWidth: '6.5rem',
                    ml: 1,
                  }}
                  id="CreateProjectModal-Button_CreateTemplate"
                >
                  Create
                </Button>
              )}
            </Box>
          ) : null}
        </ModalContent>
      ) : (
        <Loader />
      )}
    </DialogComponent>
  );
};

CreateProject.defaultProps = {
  templateId: null,
  defaultView: 'ENS',
  handleBreadcrumbs: () => {},
};

export default withBreadcrumbs(CreateProject);
